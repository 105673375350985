import "./Notes.css";

function Lines({count}) {
  let lines = [];
  for (let i = 0; i < count; i++) {
    lines.push(<li key={i}></li>);
  }
  return lines;
}

function Notes() {
  return (
    <div className="page">
      <div className="left-margins"></div>
      <div className="header"></div>
      <ul className="line-list">
        <Lines count={25}/>
      </ul>
      <div className="footer"></div>
    </div>
  );
}

export default Notes;
